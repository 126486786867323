/* app/src/modules/Content/index.js */
import PropTypes from 'prop-types'
import { Container, Row, Col } from '@mch-group/uikit-components'
import { CustomLinkTag, ContentCard } from '@components'
import styled from 'styled-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'

const ContentModule = (props) => {
  const data = {
    title: props.content_grp1_title,
    alt: `${props.content_grp1_title}`,
    subtitle: props.content_grp1_subtitle || null,
    image: uploadTransformationUrl(props.content_grp1_img, 'q_auto,c_limit,w_1000', { 'transformUrl': cloudinary.throughCloudfront }) || null,
    description: props.content_grp1_body || null,
    cta: [
      {
        id: 1,
        label: props.content_grp1_cta_label_1 || null,
        contentLink: props.content_grp1_cta_href_1 || null,
        linkRenderer: (link, label) => <CustomLinkTag url={link}>{label}</CustomLinkTag>
      },
      {
        id: 2,
        label: props.content_grp1_cta_label_2 || null,
        contentLink: props.content_grp1_cta_href_2 || null,
        linkRenderer: (link, label) => <CustomLinkTag url={link}>{label}</CustomLinkTag>
      },
      {
        id: 3,
        label: props.content_grp1_cta_label_3 || null,
        contentLink: props.content_grp1_cta_href_3 || null,
        linkRenderer: (link, label) => <CustomLinkTag url={link}>{label}</CustomLinkTag>
      },
      {
        id: 4,
        label: props.content_grp1_cta_label_4 || null,
        contentLink: props.content_grp1_cta_href_4 || null,
        linkRenderer: (link, label) => <CustomLinkTag url={link}>{label}</CustomLinkTag>
      },
      {
        id: 5,
        label: props.content_grp1_cta_label_5 || null,
        contentLink: props.content_grp1_cta_href_5 || null,
        linkRenderer: (link, label) => <CustomLinkTag url={link}>{label}</CustomLinkTag>
      }
    ],
    responsive: true,
    reverse: props.content_grp1_layout === 'left',
    urlVideo:
      (props.content_grp1_video
        ? `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/vc_h264/v1648188493/${props.content_grp1_video}`
        : props.content_grp1_youtube_video) || null,
    isHtml: true,
    videoPlayer: props.content_grp1_media_type === 'Youtube' || props.content_grp1_media_type === 'Cloudinary_video',
    videoIcon: false
  }

  return (
    <Container className='px-5 px-lg-8 px-md-7 mb-14'>
      <Row>
        <StyledCol>
          <ContentCard {...data} />
        </StyledCol>
      </Row>
    </Container>
  )
}

//UI-kit patch - [MCHGB-12550]
const StyledCol = styled(Col)`
  .card-description {
    p {
      display: block !important;
      margin-bottom: 16px;
    }
  }
`

ContentModule.propTypes = {
  content_grp1_subtitle: PropTypes.string,
  content_grp1_body: PropTypes.string,
  content_grp1_img: PropTypes.string,
  content_grp1_title: PropTypes.string,
  content_grp1_youtube_video: PropTypes.string,
  content_grp1_video: PropTypes.string,
  content_grp1_media_type: PropTypes.string,
  content_grp1_cta_label_1: PropTypes.string,
  content_grp1_cta_href_1: PropTypes.string,
  content_grp1_cta_label_2: PropTypes.string,
  content_grp1_cta_href_2: PropTypes.string,
  content_grp1_cta_label_3: PropTypes.string,
  content_grp1_cta_href_3: PropTypes.string,
  content_grp1_cta_label_4: PropTypes.string,
  content_grp1_cta_href_4: PropTypes.string,
  content_grp1_cta_label_5: PropTypes.string,
  content_grp1_cta_href_5: PropTypes.string,
  content_grp1_layout: PropTypes.string
}

export default ContentModule
